
.sidebar-wrapper{
    @apply  h-screen fixed left-0 bottom-0;
    width:20rem;
    z-index: 1;
    overflow-y:auto;
}
.sidebar-wrapper ul{
    @apply p-0;
}
.mini-sidebar-wrapper{
    @apply w-auto h-screen;
}
.sidebar-logo{
    height: 95px;
    background-color: white;
    display: flex;
    align-items: center;
    /* @apply bg-white ; */
}
.sidebar-logo img{
    width: 95%
}
.sidebar-link-wrapper{
    @apply bg-gray-sidebar h-full pt-3 ;
}

.mini-sidebar-link-wrapper{
    @apply bg-gray-sidebar h-screen ;
}

.link-section{
    background-color: #860034;
    @apply text-white py-3 px-5 capitalize
}
.link-section h1{
    font-size: 1rem;
}
.navlink-heading{
    @apply text-base no-underline;
}
