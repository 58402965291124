/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;


/* adding fonts below */
@font-face {
  font-family: "Montserrat";
  src: url(./assets/fonts/MontserratLight.ttf) format("truetype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Montserrat";
  src: url(./assets/fonts/MontserratRegular.ttf) format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url(./assets/fonts/MontserratMedium.ttf) format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url(./assets/fonts/MontserratBold.ttf) format("truetype");
  font-weight: 700;
  font-style: normal;
}

html, body, #root{
  height: 100%;
}

body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
  font-size: 1rem !important;
}
div{
  /* margin: 0 !important; */
}

h1, h2, h3, h4, h5, h6{
  font-family: "Montserrat", sans-serif;
  
}

#root{
  display: flex;
  font-size:1rem;
  /* background-color:#ededef; */
}

.table-head th, .table-head td{
  padding: 1rem 1rem !important;
  vertical-align: middle;
  
}
.table-head th{
  @apply uppercase font-normal 
}

.content-padding{
  padding-left: 300px;
}

.swiper-button-next
.swiper-button-next{
  color:#63666a;
}


.selectStyles{
  min-width: 0;
  /* margin: 0 1rem; */
  z-index: 999 !important;
  @apply w-36 mx-2 text-sm
}

.dropDown__menu{
  z-index: 999 !important;
  height: fit-content;
}

@media screen and (max-width:1024px) {
  .content-padding{
    padding-left:0px;
  }
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */


.sidebar-link-height{
  height: 40px;
  line-height: 40px;
}

.btn{
  @apply px-3 py-2 text-white rounded-sm text-sm ml-1 cursor-pointer
}

.h-checkbox{
    display: block;
    position: relative;
    /* padding-left: 35px; */
    /* margin-bottom: 12px; */
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1.5px solid #63666a;
    border-radius: 100%;
    background: #fff;
}

.h-checkbox input{
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 30px;
    width: 30px;
}

.checkmark {
    transition-duration: 0.2s;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 50%;
    width: 50%;
    border-radius: 100%;
    background-color: transparent;
}

.h-checkbox input:checked ~ .checkmark{
    background-color: #5489a3;
}
.h-checkbox input:disabled ~ .checkmark {
  /* background-color: #ccc; */
  cursor: not-allowed;
}

.activity-text-field{
  @apply rounded-sm border-1 border-gray-300 py-2 px-3 w-full focus:outline-none bg-white text-xs md:text-sm 2xl:text-base text-gray-textlight disabled:bg-gray-100;
}

.activity-detail-text-field{
  @apply rounded-sm border-1 border-gray-300 py-1 px-2 w-full focus:outline-none bg-white text-xs 2xl:text-sm text-gray-textlight disabled:bg-gray-100;
}

.pagination{
  display: flex;
  justify-content: center;
  align-items: center;
  background: #043c7b;
  margin-top: 20px;
  color: #fff;
  width: 100%;
  border-radius: 20px;
  padding: 10px 50px;
  min-width: 300px;
  /* max-width: 400px; */
  margin-left: auto;
  margin-right: auto;
  overflow-x: auto;
}

.pagination li {
  margin: 0 10px;
  height: 35px;
  width: 35px;
  border-radius: 50%;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination .active{
  border: 1px solid #fff;
}

.sm-btn{
  @apply relative group select-none cursor-pointer rounded-sm text-xss md:text-xs py-1 px-2 text-white uppercase
}
.md-btn{
  @apply relative group select-none cursor-pointer text-sm md:text-base py-3 px-6 
}


.filled-trail:before{
  content: "";
  width: 100%;
  left: 0%;
  transform: translate(-50%, 0%);
  background-color: #043c7b;
  position: absolute;
  @apply hidden lg:block w-full h-3px 2xl:h-6px top-16px 2xl:top-21px
}

.header-box {
  /* transform-origin: left top; */
  @apply w-full lg:w-16-per;
}


.field-text-size{
  @apply text-xs md:text-sm 2xl:text-base
}

.field-mb{
  @apply 2xl:mb-8 md:mb-2 mb-1
}

.field-activitiy-text{
  @apply text-xss 2xl:text-sm
}

.gradient-bg{
  background: linear-gradient(to right, #ea6877 0%, #fbb07c 25%, #fbe87c 50%, #66ad8a 100%);
}

.status-tab-header{
  @apply py-2 border-b-1 border-gray-200 bg-blue-secondary text-white px-4 flex justify-between items-center
}

.status-btn-upload{
  @apply flex-shrink-0 bg-white border-2 border-blue-secondary hover:border-red-primary hover:text-red-primary rounded-full py-1 px-2 cursor-pointer font-medium inline-block text-blue-secondary rounded-sm mx-2 text-xs
}
.status-btn-again{
  @apply flex-shrink-0 bg-green-btn border-2 border-green-btn hover:text-green-btn hover:bg-white rounded-full py-1 px-2 cursor-pointer font-medium inline-block text-white rounded-sm mx-2 text-xs
}

.status-icon-style{
  @apply flex-shrink-0 text-2xl text-gray-dark
}